/* eslint-disable */
import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";
import store from "@/store";
import { Mutations, Actions } from "@/store/enums/StoreEnums";
import JwtService from "@/core/services/JwtService";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect: "/dashboard",
    component: () => import("@/layout/Layout.vue"),
    children: [
      {
        path: "/dashboard",
        name: "dashboard",
        component: () => import("@/views/Dashboard.vue"),
      },
      {
        path: "/device-energy-details/:id",
        name: "device-energy-details",
        component: () => import("@/views/ThreePhaseDeviceDetails.vue"),
      },
      {
        path: "/device-details/:id",
        name: "device-details",
        component: () => import("@/views/DeviceDetails.vue"),
      },
      {
        path: "/solar-device-details/:id",
        name: "solar-device-details",
        component: () => import("@/views/SolarDeviceDetails.vue"),
      },
      {
        path: "/edit-device/:id",
        name: "device-edit",
        component: () => import("@/views/crafted/devices/DeviceEdit.vue"),
      },
      {
        path: "/user/profile",
        name: "user-profile",
        component: () => import("@/views/crafted/account/Overview.vue"),
      },
      {
        path: "/device/overview/:id",
        name: "device-overview",
        component: () => import("@/views/crafted/devices/DeviceOverview.vue"),
      },
      {
        path: "/device/data-log",
        name: "device-data-log",
        component: () => import("@/views/events/EventDataLog.vue"),
      },
      {
        path: "/analytics",
        name: "analytics",
        component: () => import("@/views/stats/EnergiesAnalytics.vue"),
      },
    ],
  },
  {
    path: "/stats",
    component: () => import("@/layout/Layout.vue"),
    children: [
      {
        path: "tariff/:id",
        name: "tariff",
        component: () => import("@/views/stats/General.vue"),
      },
    ],
  },
  {
    path: "/location",
    component: () => import("@/layout/Layout.vue"),
    children: [
      {
        path: "index",
        name: "location-index",
        component: () => import("@/views/locations/LocationIndex.vue"),
      },
      {
        path: "view/:id",
        name: "location-view",
        component: () => import("@/views/locations/LocationView.vue"),
      },
      {
        path: "edit/:id",
        name: "location-edit",
        component: () => import("@/views/locations/LocationEdit.vue"),
      },
      {
        path: "tariff-edit/:locationId/:tariffId",
        name: "tariff-edit",
        component: () => import("@/views/locations/EnergyTariffEdit.vue"),
      },
    ],
  },
  {
    path: "/",
    component: () => import("@/components/page-layouts/Auth.vue"),
    children: [
      {
        path: "/sign-in",
        name: "sign-in",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/SignIn.vue"),
      },
      {
        path: "/sign-in-fox",
        name: "sign-in-fox",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/SignInFoxApp.vue"),
      }
    ],
  },
  {
    // the 404 route, when none of the above matches
    path: "/404",
    name: "404",
    component: () => import("@/views/crafted/authentication/Error404.vue"),
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/404",
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

router.beforeEach(async (to, from, next) => {
  // reset config to initial state
  store.commit(Mutations.RESET_LAYOUT_CONFIG);

  const publicPages = ["/sign-in", "/sign-in-fox"];
  const authRequired = !publicPages.includes(to.path);

  if (authRequired) {
    await store.dispatch(Actions.VERIFY_AUTH,
      { api_token: JwtService.getToken() });
  }

  next();

  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 100);
});

export default router;
