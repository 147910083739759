const ID_TIMEZONE_KEY = "timezone_key" as string;

/**
 * @description get timezone form localStorage
 */
export const getUserTimezone = (): string | null => {
  return window.localStorage.getItem(ID_TIMEZONE_KEY);
};

/**
 * @description save user timezone into localStorage
 * @param token: string
 */
export const saveUserTimezone = (timezone: string): void => {
  window.localStorage.setItem(ID_TIMEZONE_KEY, timezone);
};

/**
 * @description remove user timezone form localStorage
 */
export const destroyUserTimezone = (): void => {
  window.localStorage.removeItem(ID_TIMEZONE_KEY);
};

export default { getUserTimezone, saveUserTimezone, destroyUserTimezone };
