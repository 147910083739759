/* eslint-disable */
import { App } from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
import JwtService from "@/core/services/JwtService";
import { AxiosResponse, AxiosRequestConfig } from "axios";
import store from "@/store";
import { Mutations } from "@/store/enums/StoreEnums";
import router from "@/router";

/**
 * @description service to call HTTP request via Axios
 */
class ApiService {
  /**
   * @description property to share vue instance
   */
  public static vueInstance: App;

  /**
   * @description initialize vue axios
   */
  public static init(app: App<Element>) {
    ApiService.vueInstance = app;
    ApiService.vueInstance.use(VueAxios, axios);
    ApiService.vueInstance.axios.defaults.baseURL = process.env.VUE_APP_API_URL;
    //handle unauthorized
    ApiService.handleUnauthorized();
  }

  /**
   * @description handle 401 error code, redirect to login page
   */
  private static handleUnauthorized() {
    //set sending cookies with origin different than *
    ApiService.vueInstance.axios.defaults.withCredentials = true;
    ApiService.vueInstance.axios.interceptors.request.use(
      (config) => {
        const token = JwtService.getToken();
        if (token) {
          config.headers = config.headers ?? {};
          config.headers["Authorization"] = 'Bearer ' + token;
        }
        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );
    ApiService.vueInstance.axios.interceptors.response.use(
      (response) => {
        const v = response.headers['x-app-ver'] || '0.1.0';
        if (v !== localStorage.getItem('app-vers') && response.config.method == 'get') {
          localStorage.setItem('app-vers', v);
          window.location.reload(); //reload if newer version is available
        }
        return Promise.resolve(response);
      },
      async (error) => {
        const originalConfig = error.config;
        if (originalConfig.url !== "/account/login" && error.response) {
          // Access Token was expired
          if (error.response.status === 401 && !originalConfig._retry) {
            originalConfig._retry = true;
            try {
              const response = await ApiService.post("account/refreshtoken", store.getters.currentUser);
              JwtService.destroyToken();
              store.commit(Mutations.SET_AUTH, response.data);
              return ApiService.vueInstance.axios(originalConfig);
            } catch (_error) {
              store.commit(Mutations.PURGE_AUTH);
              return Promise.reject(_error);
            }
          }
          if (error.response.status === 404 && !originalConfig._retry) {
            originalConfig._retry = true;
            router.push({ name: "404" });
          }
        }
        return Promise.reject(error);
      }
    );
  }
  /**
   * @description set the default HTTP request headers
   */
  public static setHeader(): void {
    ApiService.vueInstance.axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${JwtService.getToken()}`;
    ApiService.vueInstance.axios.defaults.headers.common["Accept"] =
      "application/json";
  }

  /**
   * @description send the GET HTTP request
   * @param resource: string
   * @param params: AxiosRequestConfig
   * @returns Promise<AxiosResponse>
   */
  public static query(
    resource: string,
    params: AxiosRequestConfig
  ): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios.get(resource, params);
  }

  /**
   * @description send the GET HTTP request
   * @param resource: string
   * @param slug: string
   * @returns Promise<AxiosResponse>
   */
  public static get(
    resource: string,
    slug = "" as string
  ): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios.get(`${resource}/${slug}`);
  }

  /**
   * @description set the POST HTTP request
   * @param resource: string
   * @param params: AxiosRequestConfig
   * @returns Promise<AxiosResponse>
   */
  public static post(
    resource: string,
    params: AxiosRequestConfig
  ): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios.post(`${resource}`, params);
  }

  /**
   * @description set the POST HTTP request for file download
   * @param resource: string
   * @param params: AxiosRequestConfig
   * @returns Promise<AxiosResponse>
   */
  public static download(
    resource: string,
    params: AxiosRequestConfig
  ): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios.post(`${resource}`, params, {
      responseType: 'blob'
    });
  }

  /**
   * @description send the UPDATE HTTP request
   * @param resource: string
   * @param slug: string
   * @param params: AxiosRequestConfig
   * @returns Promise<AxiosResponse>
   */
  public static update(
    resource: string,
    slug: string,
    params: AxiosRequestConfig
  ): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios.put(`${resource}/${slug}`, params);
  }

  /**
   * @description Send the PUT HTTP request
   * @param resource: string
   * @param params: AxiosRequestConfig
   * @returns Promise<AxiosResponse>
   */
  public static put(
    resource: string,
    params: AxiosRequestConfig
  ): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios.put(`${resource}`, params);
  }

  /**
   * @description Send the DELETE HTTP request
   * @param resource: string
   * @returns Promise<AxiosResponse>
   */
  public static delete(resource: string): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios.delete(resource);
  }
}

export default ApiService;
