import LayoutConfigTypes from "@/core/config/LayoutConfigTypes";

const config: LayoutConfigTypes = {
  themeName: "FoxTelemetry",
  themeVersion: "0.0.1",
  main: {
    type: "default",
    primaryColor: "#ff7300",
    logo: {
      dark: "media/logos/fox-logo-dark.png",
      light: "media/logos/fox-logo-light.png",
    },
    headerLogo: {
      dark: "media/logos/h_logo_dark.png",
      light: "media/logos/h_logo_light.png",
    },
  },
  illustrations: {
    set: "dozzy-1",
  },
  loader: {
    logo: "media/logos/preloader.png",
    display: true,
    type: "default",
  },
  scrollTop: {
    display: true,
  },
  header: {
    display: true,
    menuIcon: "font",
    width: "fixed",
    fixed: {
      desktop: true,
      tabletAndMobile: true,
    },
  },
  toolbar: {
    display: true,
    width: "fixed",
    fixed: {
      desktop: false,
      tabletAndMobile: false,
    },
  },
  aside: {
    display: false,
    theme: "dark",
    fixed: true,
    menuIcon: "svg",
    minimized: false,
    minimize: true,
    hoverable: true,
  },
  content: {
    width: "fixed",
  },
  footer: {
    width: "fluid",
  },
  foxAppMode: false,
};

export default config;
