/* eslint-disable */
import ApiService from "@/core/services/ApiService";
import SignalRService from "@/core/services/SignalRService";
import JwtService from "@/core/services/JwtService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import MetaDataService from "@/core/services/MetaDataService";

export interface User {
  fullName: string,
  email: string;
  country: string
  password: string;
  token: string;
}

export interface UserAuthInfo {
  errors: unknown;
  user: User;
  isAuthenticated: boolean;
  isHubConnected: boolean;
}

@Module
export default class AuthModule extends VuexModule implements UserAuthInfo {
  errors = {};
  user = {} as User;
  isAuthenticated = !!JwtService.getToken();
  isHubConnected = false;

  /**
   * Get current user object
   * @returns User
   */
  get currentUser(): User {
    return this.user;
  }

  /**
   * Verify user authentication
   * @returns boolean
   */
  get isUserAuthenticated(): boolean {
    return this.isAuthenticated;
  }

  /**
   * Get authentification errors
   * @returns array
   */
  get getErrors() {
    return this.errors;
  }

  get getIsHubConnected(): boolean {
    return this.isHubConnected;
  }

  @Mutation
  [Mutations.SET_ERROR](error) {
    this.errors = { ...error };
  }

  @Mutation
  [Mutations.SET_AUTH](user) {
    JwtService.saveToken(user.token);
    this.isAuthenticated = true;
    this.user = user;
    MetaDataService.saveUserTimezone(user.country);
    this.errors = {};
  }

  @Mutation
  [Mutations.SET_USER](user) {
    this.user = user;
    MetaDataService.saveUserTimezone(user.country);
  }

  @Mutation
  [Mutations.SET_TOKEN](jwt) {
    JwtService.saveToken(jwt);
  }

  @Mutation
  [Mutations.SET_PASSWORD](password) {
    this.user.password = password;
  }

  @Mutation
  [Mutations.PURGE_AUTH]() {
    this.isAuthenticated = false;
    this.user = {} as User;
    this.errors = [];
    JwtService.destroyToken();
  }

  @Mutation
  [Mutations.SET_HUB_CONNECTED](connected) {
    this.isHubConnected = connected;
  }

  @Action
  [Actions.LOGIN](credentials) {
    return ApiService.post("account/login", credentials)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_AUTH, data);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }

  @Action
  [Actions.UPDATE_USER](userData) {
    return ApiService.post("account/update", userData)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_USER, data);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }

  @Action
  [Actions.USER_INFO]() {
    return ApiService.get("account/userInfo")
      .then(({ data }) => {
        this.context.commit(Mutations.SET_USER, data);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }

  @Action
  [Actions.LOGOUT_SIGNALR]() {
    return SignalRService.disconnect().then(() => {
      this.context.commit(Mutations.PURGE_AUTH);
      this.context.commit(Mutations.SET_HUB_CONNECTED, false);
    })
      .catch(() => {
      this.context.commit(Mutations.PURGE_AUTH);
      this.context.commit(Mutations.SET_HUB_CONNECTED, false);
    });
  }

  @Action
  [Actions.LOGOUT]() {
    return ApiService.get("account/logout").then(() => {
      this.context.commit(Mutations.PURGE_AUTH);
    }).catch(() => {
      this.context.commit(Mutations.PURGE_AUTH);
    });
  }

  @Action
  [Actions.REGISTER](credentials) {
    return ApiService.post("register", credentials)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_AUTH, data);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }

  @Action
  [Actions.FORGOT_PASSWORD](payload) {
    return ApiService.post("forgot_password", payload)
      .then(() => {
        this.context.commit(Mutations.SET_ERROR, {});
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }

  @Action
  [Actions.VERIFY_AUTH](payload) {
    ApiService.setHeader();
    return ApiService.post("account/verifytoken", payload)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_TOKEN, data.token);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.PURGE_AUTH);
        if (response)
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }

  @Action
  [Actions.REFRESH_TOKEN](payload) {
    return ApiService.post("account/refreshtoken", payload)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_AUTH, data);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.PURGE_AUTH);
        if (response)
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }

  @Action
  [Actions.INIT_CONNECTIONS]() {
    //if logged in connect to signalR
    return SignalRService.connect().then(() => {
      this.context.commit(Mutations.SET_HUB_CONNECTED, true);
    })
    .catch(() => {
      this.context.commit(Mutations.SET_HUB_CONNECTED, false);
    });;
  }

  @Action
  [Actions.REGISTER_SIGNALR_CALLBACKS]() {
    SignalRService.registerConnectionCallbacks(
      () => {
        /* onclose callback */
        this.context.commit(Mutations.SET_HUB_CONNECTED, false);
      },
      () => {
        /* onreconnecting callback */
        this.context.commit(Mutations.SET_HUB_CONNECTED, false);
      },
      (connectionId?: string) => {
        /* onreconnected callback */
        this.context.commit(Mutations.SET_HUB_CONNECTED, true);
      });
  }
}
