/* eslint-disable */
import {
    HubConnectionBuilder,
    HubConnection,
    LogLevel,
    HubConnectionState
} from '@microsoft/signalr'
import JwtService from "@/core/services/JwtService";

class SignalRService
{
    private static connection: HubConnection;

    /**
     * @description  Init SignalR connection (without connect)
     */
    public static init()
    {
        this.connection = new HubConnectionBuilder()
            .withUrl(
                process.env.VUE_APP_SIGNALR_API_URL == null ?
                    "https://localhost:7171" : process.env.VUE_APP_SIGNALR_API_URL,
                {
                    accessTokenFactory: () => {
                        //const token = JwtService.getToken();
                        //return token == null ? "" : token;
                        return JwtService.getToken() ?? "";
                    }
                })
            .configureLogging(
                process.env.VUE_APP_SIGNALR_LOGGING == "debug" ? LogLevel.Debug : LogLevel.None)
            .withAutomaticReconnect()
            .build();
    }

    /**
     * Connect to HUB with connection defined in init method
     * @returns Promise to evaluate
     */
    public static connect(): Promise<void>
    {
        return this.connection.start();
    }

    /**
     * Disconnect from HUB
     * @returns Promise to evaluate
     */
    public static disconnect(): Promise<void>
    {
        if (this.connection.state === HubConnectionState.Connected)
            return this.connection.stop();
        return new Promise<void>((resolve, reject) => {
            resolve();
            reject();
        });
    }

    /**
     * Register connection callback's
     * @param onClose - on connection close callback
     * @param onReconnecting - on reconnecting callback
     * @param onReconnected - on reconnected callback (provides new connectionId)
     */
    public static registerConnectionCallbacks(onClose: (error?: Error) => void,
        onReconnecting: (error?: Error) => void,
        onReconnected: (connectionId?: string) => void): void
    {
        this.connection.onclose(onClose);
        this.connection.onreconnecting(onReconnecting);
        this.connection.onreconnected(onReconnected);
    }

    /**
     * Send message to corresponding methodName
     * @param methodName - method name to send data
     * @param args - data to send
     * @returns Promise evalueted when server respond on message
     */
    public static sendMessage(methodName: string, ...args: any[]): Promise<void>
    {
        return this.connection.send(methodName, ...args);
    }

    /**
     * Invoke method on server and return coresponding data
     * @param methodName - method name to call
     * @param args - arguments to pass
     * @returns Promise evalueted when server respond on message
     */
    public static invokeMethod<T = any>(methodName: string, ...args: any[]): Promise<T>
    {
        return this.connection.invoke(methodName, ...args);
    }

    /**
     * Register message
     * @param methodName
     * @param callback
     */
    public static registerMessageHandler(methodName: string,
        callback: (...args: any[]) => void)
    {
        this.connection.on(methodName, callback);
    }

    /**
     * Unregister message handler.
     * Be sure that callback is the same object as registred before
     * even it cannot be removed
     * @param methodName - methodName
     * @param callback - callback to unregister
     */
    public static unregisterMessageHandler(methodName: string,
        callback: (...args: any[]) => void)
    {
        this.connection.off(methodName, callback);
    }
}

export default SignalRService;