enum Actions {
  // action types
  ADD_BODY_CLASSNAME = "addBodyClassName",
  REMOVE_BODY_CLASSNAME = "removeBodyClassName",
  ADD_BODY_ATTRIBUTE = "addBodyAttribute",
  REMOVE_BODY_ATTRIBUTE = "removeBodyAttribute",
  ADD_CLASSNAME = "addClassName",
  VERIFY_AUTH = "verifyAuth",
  REFRESH_TOKEN = "refreshToken",
  LOGIN = "login",
  FOX_APP_LOGIN = "foxAppLogin",
  LOGOUT = "logout",
  LOGOUT_SIGNALR = "logoutSignalR",
  REGISTER = "register",
  UPDATE_USER = "update-user",
  USER_INFO = "user-info",
  FORGOT_PASSWORD = "forgotPassword",
  SET_BREADCRUMB_ACTION = "setBreadcrumbAction",
  SET_THEME_MODE_ACTION = "setThemeModeAction",
  INIT_CONNECTIONS = "initIfLoggedIn",
  SHOW_PAGE_LOADING = "showPageLoading",
  REGISTER_SIGNALR_CALLBACKS = "registerSignalRCallback",
}

enum Mutations {
  // mutation types
  SET_CLASSNAME_BY_POSITION = "appendBreadcrumb",
  PURGE_AUTH = "logOut",
  SET_AUTH = "setAuth",
  SET_USER = "setUser",
  SET_PASSWORD = "setPassword",
  SET_ERROR = "setError",
  SET_BREADCRUMB_MUTATION = "setBreadcrumbMutation",
  SET_LAYOUT_CONFIG = "setLayoutConfig",
  RESET_LAYOUT_CONFIG = "resetLayoutConfig",
  OVERRIDE_LAYOUT_CONFIG = "overrideLayoutConfig",
  OVERRIDE_PAGE_LAYOUT_CONFIG = "overridePageLayoutConfig",
  SET_THEME_MODE_MUTATION = "setThemeModeMutation",
  SET_HUB_CONNECTED = "setHubConnected",
  SET_TOKEN = "setToken",
  SET_FOX_APP_MODE = "setFoxAppMode",
  INIT_FOX_APP_MODE = "initFoxAppMode",
}

export { Actions, Mutations };
