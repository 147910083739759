/* eslint-disable */

import { createApp } from "vue";
import App from "./App.vue";

import router from "@/router/index";
import store from "./store";
import ElementPlus, { dayjs } from "element-plus";
import i18n from "@/core/plugins/i18n";
import pl from 'element-plus/es/locale/lang/pl';
import en from 'element-plus/es/locale/lang/en';
import tr from 'element-plus/es/locale/lang/tr';
import 'dayjs/locale/pl';
import 'dayjs/locale/en';
import 'dayjs/locale/tr';

//imports for app initialization
import ApiService from "@/core/services/ApiService";
import SignalRService from "./core/services/SignalRService";
import { initApexCharts } from "@/core/plugins/apexcharts";
import { initInlineSvg } from "@/core/plugins/inline-svg";
import { initVeeValidate } from "@/core/plugins/vee-validate";

import "@/core/plugins/prismjs";
const app = createApp(App);

const supportedLocales = {
    pl: pl,
    tr: tr,
    en: en,
};

app.use(store);
app.use(router);
dayjs.locale(localStorage.getItem("lang") ?? "en");

app.use(ElementPlus, {
  locale: supportedLocales[localStorage.getItem("lang") ?? "en"],
});

ApiService.init(app);
//init signalR object
SignalRService.init();

initApexCharts(app);
initInlineSvg(app);
initVeeValidate();

app.use(i18n);

app.mount("#app");
