/* eslint-disable */
import { createI18n } from "vue-i18n/index";

const messages = {
  en: {
    signInView: {
      signInTitle: "Sign In to Telemetry System",
      email: "Email",
      password: "Password",
      continue: "Continue",
      successfull: "You have successfully logged in!",
      failed: "Try again!",
      confirm: "Ok, got it!",
      rememberMe: "Remember Me",
      token: "Token",
      params: "Params",
      showDemo: "Show demo"
    },
    deviceListWidget: {
      title: "Registered devices",
      total: "Total",
      dName: "Name",
      dModel: "Model",
      dStatus: "Status",
      dActions: "Actions",
      location: "Location",
      tariff: "Tariff",
    },
    deviceDetailsView: {
      currentVoltage: "Actual Voltage",
      current: "Actual Current",
      currentPower: "Actual  Power",
      voltageTitle: "Voltage",
      powerTitle: "Power",
      currentTitle: "Current",
      energyTitle: "Energy active",
      energyTitleExport: "Energy given",
      energyTitleImport: "Energy consumed",
      energyReactiveTitle: "Energy reactive",
      energyReactiveExportTitle: "Energy reactive given",
      energyReactiveImportTitle: "Energy reactive consumed",
      voltageDescription:
        "Shows voltage with min and max values captured in time range.",
      currentDescription:
        "Shows current with min and max values captured in time range.",
      powerDescription:
        "Shows power with min and max values captured in time range.",
      energyDescription:
        "Shows consumed and given away active energy captured in time range.",
      energy3Description:
        "Shows consumed and given away active energy sum from each phases, captured in time range.",
      energyReactiveDescription:
        "Shows consumed and given away reactive energy captured in time range.",
      phase1: "Phase 1",
      phase2: "Phase 2",
      phase3: "Phase 3",
      summary: "Summary",
      phase1dsc: "Phase 1 data",
      phase2dsc: "Phase 2 data",
      phase3dsc: "Phase 3 data",
      summarydsc: "Data of 3 phases",
      power3phasesDescription: "Shows power sum from each phases, captured in time range.",
      voltage3phasesDescription: "Shows voltage from each phases, captured in time range.",
      current3phasesDescription: "Shows current from each phases, captured in time range.",
      energy3phasesImportDescription:
        "Shows consumed energy sum from each phases, captured in time range.",
      energy3phasesExportDescription:
        "Shows given away energy sum from each phases, captured in time range.",
      energyReactive3phasesImportDescription:
        "Shows consumed reactive energy sum from each phases, captured in time range.",
      energyReactive3phasesExportDescription:
        "Shows given away reactive energy sum from each phases, captured in time range.",
      energyConsumption: "Energy consumption",
      fromBillingPeriod: "From the billing period",
      selectBillingPeriod: "Select billing period",
      noBillingPeriod: "Billing periods not set.",
      active: "Active",
      reactive: "Reactive",
      volt: "Volts",
      amper: "Amps",
      watt: "Watts",
      outPower: "given",
      details: "Details",
      energyProductionTitle: "Energy production",
      carbonEmissionHint: "Your carbon emission is less. Thank you!",
      energyProductionDescription: "Shows given away active energy captured in time range.",
      solarPowerDescription: "Shows power value captured in time range.",
      energyBalance: "Balance",
      energyBalanceDescription: "Balance of active energy imported and exported, recorded in a time interval.",
      selectView: "Select view"
    },
    chartsDropdowns: {
      chartOptions: "Chart options",
      date: "Select date",
      dateRange: "Select date range",
      simpleChartDateHint: "Data will be shown from start date plus 24h.",
      reset: "Reset",
      apply: "Apply",
    },
    pagesTitle: {
      dashboard: "Dashboard",
      devicedetails: "Device overview",
      deviceData: "Device data",
      locationIndex: "Locations",
    },
    time: {
      hour: "Hour",
      minute: "Minute",
      seconds: "Seconds",
      day: "Day",
      month: "Month",
      week: "Week",
      year: "Year",
    },
    notifications: {
      title: "Notifications",
      events: "Events",
      empty: "No new notifications.",
      showAll: "View all",
    },
    themeMenu: {
      light: "Light",
      dark: "Dark",
      system: "System"
    },
    devParameters: {
      pave1: "Power L1",
      pave2: "Power L2",
      pave3: "Power L3",
      pmax1: "Power L1 - max",
      pmax2: "Power L2 - max",
      pmax3: "Power L3 - max",
      pmin1: "Power L1 - min",
      pmin2: "Power L2 - min",
      pmin3: "Power L3 - min",
      vave1: "Voltage L1",
      vave2: "Voltage L2",
      vave3: "Voltage L3",
      vmax1: "Voltage L1 - max",
      vmax2: "Voltage L2 - max",
      vmax3: "Voltage L3 - max",
      vmin1: "Voltage L1 - min",
      vmin2: "Voltage L2 - min",
      vmin3: "Voltage L3 - min",
      iave1: "Current L1",
      iave2: "Current L2",
      iave3: "Current L3",
      imax1: "Current L1 - max",
      imax2: "Current L2 - max",
      imax3: "Current L3 - max",
      imin1: "Current L1 - min",
      imin2: "Current L2 - min",
      imin3: "Current L3 - min",
      eai1: "Active energy import L1",
      eai2: "Active energy import L2",
      eai3: "Active energy import L3",
      eae1: "Active energy export L1",
      eae2: "Active energy export L2",
      eae3: "Active energy export L3",
      eri1: "Reactive energy import L1",
      eri2: "Reactive energy import L2",
      eri3: "Reactive energy import L3",
      ere1: "Reactive energy export L1",
      ere2: "Reactive energy export L2",
      ere3: "Reactive energy export L3",
      pfave1: "Power factor L1",
      pfave2: "Power factor L2",
      pfave3: "Power factor L3",
      pfmax1: "Power factor L1 - max",
      pfmax2: "Power factor L2 - max",
      pfmax3: "Power factor L3 - max",
      pfmin1: "Power factor L1 - min",
      pfmin2: "Power factor L2 - min",
      pfmin3: "Power factor L3 - min",
      energy3phases: "Total energy export",
      renergy3phases: "Total reactive energy export",
      power3phases: "Power sum",
      energy3phases_export: "Total energy export",
      energy3phases_import: "Total energy import",
      renergy3phases_export: "Total reactive energy export",
      renergy3phases_import: "Total reactive energy import",
      frq1: "Frequency",
      vdc1: "Voltage DC1",
      vdc2: "Voltage DC2",
      vdc3: "Voltage DC3",
      idc1: "Current DC1",
      idc2: "Current DC2",
      alarm: "Alarm",
      balance: "Balance",
    },
    userProfile: {
      account: "Account",
      title: "Profile details",
      edit: "Edit profile",
      myProfile: "My profile",
      language: "Language",
      email: "Email addess",
      emailTooltip: "Email cannot be edited",
      fullname: "Full name",
      country: "Country",
      timezone: "Timezone",
      countryTooltip: "Country of installation, useful to set timezone",
      countryRequired: "Country is required",
      timezoneTooltip: "Timezone of device installation",
      timezoneRequired: "Timezone is required",
      infoTitleOk: "Everything is ok!",
      infoDescriptionOk: "It looks like we didn't find any issues with your account.",
      infoTitleWrong: "We need your attention",
    },
    languages: {
      english: "English",
      polish: "Polish",
      turkish: "Turkish",
    },
    forms: {
      successfullySaved: "Data has been successfully saved.",
      successfullyExported: "Data was exported successfully.",
      errorInSaving: "Unable to save data. Check form.",
      errorInExporting: "Exporting data failed.",
      submit: "Submit",
      discard: "Discard",
      ok: "Ok",
      okGotIt: "Ok, got it!",
      edit: "Edit",
      cancel: "Cancel",
      export: "Export",
    },
    deviceOverview: {
      title: "Device details",
      edit: "Edit device",
      name: "Known name",
      friendlyName: "Common name",
      typeName: "Type name",
      mac: "Mac",
      modelName: "Model name",
      registeredDate: "Registered date",
      modifiedDate: "Modified date",
      registeredDateTooltip: "Date when device was registered",
      modifiedDateTooltip: "Date when device modified itself",
      nameTooltip: "User defined name, you can change it",
      billingStartDate: "Billing period, start date",
      billingNoMonths: "Number of months in billing period",
      defaultTariff: "Device billing tariff",
      location: "Location",
      notificationsFromDevice: "Notifications from device",
      notificationsEnabled: "Enabled",
      notificationsDisabled: "Disabled",
      notifyWhenVoltageDrops: "Notify when voltage drops under",
      notifyWhenVoltageRises: "Notify when voltage exceeds more than",
      notifyWhenTotalCosumptionAbove: "Notify when total energy consumption exceeds more than",
      disableNotificationsAtNight: "Disable notifications at night (22:00 - 7:00)",
      notificationInterval: "Notification interval",
      muteNotifications: "Mute notifications",
      muteNotificationsInfo: "Notifications will not be sent from the telemetry system. Includes all types of notifications."
    },
    tariff: {
      pageTitle: "Stats of energy consumption",
      chceckUsages: "Check usages",
      g11Description: "Podstawowa taryfa roliczenieowa. Jedna stawka za energię elektryczną o każdej porze dnia i nocy.",
      g12Description: "Taryfa dwustrefowa. Dla korzystających z energii elektrycznej głównie w nocy, w szczególności na potrzeby ogrzewania.",
      g12asDescription: "Taryfa dwustrefowa. Dostosowana do celów grzewczych.",
      g12rDescription: "Taryfa dwustrefowa. Podobna do g12, nastawiona jeszcze bardziej na zużycie energii w nocy.",
      g12nDescription: "Taryfa dwustrefowa. Rozliczenie w taryfie dzienne i nocnej z rozszerzoną strefą nocną o niedzielę.",
      g12wDescription: "Taryfa dwustrefowa. Niższa cena prądu w weekend, w nocy i wybranych godzinach dnia.",
      profitCostText: "Potentail cost/profit",
      currentConsumption: "Current consumption",
      showCosts: "Show costs",
      area1: "Day zone",
      area2: "Night zone",
      currentUsageDescription: "Energy consumption for the current month at the default tariff",
      attentionInfo: "The presented data are simulated and cannot be basis for potential claims against any subject.",
      yourTariff: "Your tariff",
      tariffsTitile: "Tariffs",
      priceArea1: "Price in area one (daily)",
      priceArea2: "Price in area two (nightly)",
      priceArea3: "Price in area third",
      priceConst: "Const price",
      priceConstInfo: "Caluclate all fixed prices from your invoice and set it here",
      g11SummaryText: "Basic household tariff",
      gxSummaryText: "You consume energy in two zones: day and night",
      //opisy dla taryf, przenieść tłumaczenia
      area1_2: "Day/night zone",
      g11DescTitile: "Tariff G11",
      g12DescTitile: "Tariff G12",
      g12asDescTitile: "Tariff G12as",
      g12nDescTitile: "Tariff G12n",
      g12rDescTitile: "Tariff G12r",
      g12wDescTitile: "Tariff G12w",
      monday_sunday: "Monday - sunday",
      monday_saturday: "Monday - saturday",
      monday_friday: "Monday - friday",
      saturdaySunday: "Saturday, sunday and holidays",
      sundayFeasts: "Sunday and holidays",
      zone: "Zone",
      wholeDay: "Round the clock",
      tariffRule: "Tariff rule:",
      g11DescRule: "One rate for electricity at any time of the day or night. The offer will be given to those using electricity mainly during the day, not using electricity for heating purposes.",
      g12DescRule: "It enables the settlement of consumed energy in two time zones. An offer for those who use electricity mainly at night, in particular for heating purposes.",
      g12asDescRule: "Two-zone group: day and night zone.",
      g12nDescRule: "Two-zone group: day and night zones with extended night zone by Sunday.",
      g12rDescRule: "Extended two-zone group: day and night zones.",
      g12wDescRule: "An offer for customers using day and night heating, with the night zone extended to include Saturdays and Sundays",
      cheaper: "Cheaper",
      moreExpensive: "more expensive",
      nightPeriod: "Night period",
      dailyPeriod: "Daily period",
      southernPeriod: "Southern period",
      morningPeriod: "Morning period",
      eveningPeriod: "Evening period",
      selectTariff: "Select tariff",
      noTariffSelected: "Didn't choose a tariff",
      compareTariff: "Compare tariffs",
      compareTariffDescription: "You can compare your current and recent usage with the other tariffs to help you choose the right tariff for your new billing period."
    },
    location: {
      indexTitle: "Locations",
      name: "Name",
      code: "Code",
      address: "Address",
      city: "City",
      timezone: "Time Zone",
      energyTariff: "Eneregy Tariff",
      edit: "Edit location",
      yourLocations: "Your locations",
      newLocationTitle: "Add new location",
      availableEnergyTariffs: "Available energy tariffs",
      resetDefaut: "Reset default",
      restoredDefault: "Restored to default values",
      successfullyDeleted: "Location successfully deleted",
    },
    toasts: {
      success: "Success",
      warning: "Warning",
      info: "Info",
      help: "Help",
    },
    validators: {
      number: "Must be a valid number, fix your input",
      min: "Must be greater than or equal to ",
      max: "Must be less than or equal to ",
      dateRange: "Date range is required",
      parameterMin: "Please select at least one parameter",
      exportTo: "Select file type to export",
      required: "Field cannot be empty"
    },
    exportData: {
      title: "Export data",
      parameters: "Parameters",
      parametersHint: "Select parameters to export",
      dateRange: "Select date range",
      customFileName: "Custom file name",
      customFileNameHint: "Type custom file name or leave empty",
      exportAllParams: "Export all available parameters",
      exportTo: "Export to file type",
      exportUnavailableTitle: "Export unavailable",
      exportUnavailableDesc: "Export is unavailable because server is busy. Try again later.",
      exportInBackround: "Exporting data scheduled. We will inform you when file be ready.",
    },
    times: {
      min15: "15 min.",
      min30: "30 min.",
      min60: "1 hour",
      min1440: "24 hours"
    },
    analytics: {
      selectOperation: "Select action",
      dailyHeatmap: "Daily energy consumption (heatmap)",
      monthlyHeatmap: "Monthly energy consumption (heatmap)",
      weeklyHeatmap: "Weekly energy consumption (heatmap)",
      yearlyHeatmap: "Yearly energy consumption (heatmap)",
      selectDevices: "Select devices",
      selectDateRange: "Select date range",
      sumCmpDesc: "Sum of energies consump.",
      compareTitle: "Comparative analysis",
      compareDescription: "Compare devices parameters with predefined action or create your own",
      noData: "No data",
      analyze: "Analyze",
    },
    providedBy: "Provided by",
    manufacturer: "F&F Filipowski Sp. k.",
    pleaseWait: "Please wait...",
    dashboard: "Dashboard",
    authentication: "Authentication",
    signIn: "Sign-in",
    signUp: "Sign-up",
    signOut: "Sign out",
    passwordReset: "Password Reset",
    error404: "Seems there is nothing here",
    error500: "Internal server error. Please contact with server administrator.",
    daily: "Daily",
    monthly: "Monthly",
    yearly: "Yearly",
    total: "Total",
    select: "Select",
    noData: "Empty results",
    add: "Add",
    deletePrompt: "Confirm delete operation. Are you shure?",
    yes: "Yes",
    no: "No",
    edit: "Edit",
    attention: "Attention",
    date: "Date",
    selectDate: "Select date",
    selectDateGlobalDesc: "Selected date will be assigned to all charts",
    energyTableView: "Table view",
    lp: "No.",
    value: "Value",
    unit: "Unit",
  },
  pl: {
    signInView: {
      signInTitle: "Zaloguj się do systemu Telemetrii",
      email: "Email",
      password: "Hasło",
      continue: "Kontynuuj",
      successfull: "Zalogowano!",
      failed: "Spróbuj ponownie!",
      confirm: "Ok",
      rememberMe: "Zapamiętaj mnie",
      token: "Token",
      params: "Params",
      showDemo: "Zobacz demo"
    },
    deviceListWidget: {
      title: "Zarejestrowane urządzenia",
      total: "Ilość",
      dName: "Nazwa",
      dModel: "Model",
      dStatus: "Status",
      dActions: "Akcje",
      location: "Lokalizacja",
      tariff: "Taryfa",
    },
    deviceDetailsView: {
      currentVoltage: "Aktualne napięcie",
      current: "Aktualne natężenie",
      currentPower: "Aktualna moc",
      voltageTitle: "Napięcie",
      powerTitle: "Moc",
      currentTitle: "Prąd",
      energyTitle: "Energia czynna",
      energyReactiveTitle: "Energia bierna",
      energyTitleExport: "Energia oddana",
      energyTitleImport: "Energia pobrana",
      energyReactiveExportTitle: "Energia bierna oddana",
      energyReactiveImportTitle: "Energia bierna pobrana",
      voltageDescription:
        "Wartość napięcia z poziomiem min. i max. zarejestrowana w przedziale czasu.",
      currentDescription:
        "Wartość prądu z poziomiem min. i max. zarejestrowana w przedziale czasu.",
      powerDescription:
        "Wartość mocy z poziomem min. i max. zarejestrowana w przedziale czasu.",
      energyDescription:
        "Wartość energii czynnej pobranej i oddanej, zarejestrowana w przedziale czasu.",
      energy3Description:
        "Wartość energii czynnej pobranej i oddanej (zsumowna z 3 faz), zarejestrowana w przedziale czasu.",
      energyReactiveDescription:
        "Wartość energii biernej pobranej i oddanej, zarejestrowana w przedziale czasu.",
      phase1: "Faza 1",
      phase2: "Faza 2",
      phase3: "Faza 3",
      summary: "Podsumowanie",
      phase1dsc: "Wartości fazy 1",
      phase2dsc: "Wartości fazy 2",
      phase3dsc: "Wartości fazy 3",
      summarydsc: "Zagregowane wartości z 3 faz.",
      power3phasesDescription: "Suma mocy z 3 faz, zarejestrowana w przedziale czasu.",
      voltage3phasesDescription: "Wartość napięcia z każdej fazy, zarejestrowana w przedziale czasu.",
      current3phasesDescription: "Wartość natężenia z każdej fazy, zarejestrowana w przedziale czasu.",
      energy3phasesImportDescription:
        "Suma energii pobranej z 3 faz, zarejestrowana w przedziale czasu.",
      energy3phasesExportDescription:
        "Suma energii oddanej z 3 faz, zarejestrowana w przedziale czasu.",
      energyReactive3phasesImportDescription:
        "Suma pobranej energii biernej z 3 faz, zarejestrowana w przedziale czasu.",
      energyReactive3phasesExportDescription:
        "Suma oddanej energii biernej z 3 faz, zarejestrowana w przedziale czasu.",
      energyConsumption: "Zużycie energii",
      fromBillingPeriod: "Z okresu rozliczeniowego",
      selectBillingPeriod: "Wybierz okres rozliczeniowy",
      noBillingPeriod: "Brak zdefiniowanych okresów rozliczeniowych.",
      active: "Czynnej",
      reactive: "Biernej",
      volt: "Woltów",
      amper: "Amperów",
      watt: "Watów",
      outPower: "oddana",
      details: "Szczegóły",
      energyProductionTitle: "Produkcja energii",
      carbonEmissionHint: "Twój ślad węglowy jest niższy. Dziękujemy, że dbasz o naszą planetę!",
      energyProductionDescription: "Wartość energii oddanej, zarejestrowana w przedziale czasu",
      solarPowerDescription: "Wartość mocy zarejestrowana w przedziale czasu.",
      energyBalance: "Bilans energii",
      energyBalanceDescription: "Bilans energii czynnej pobranej i oddanej, zarejestrowanej w przedziale czasu.",
      selectView: "Select view"
    },
    chartsDropdowns: {
      chartOptions: "Opcje wykresu",
      date: "Wybierz datę",
      dateRange: "Wybierz zakres dat",
      simpleChartDateHint: "Dane na wykresie będą widoczne z przedziału: wybrana data plus 24 godziny",
      reset: "Reset",
      apply: "Zastosuj",
    },
    pagesTitle: {
      dashboard: "Dashboard",
      devicedetails: "Informacje o urządzeniu",
      deviceData: "Dane urządzenia",
      locationIndex: "Lokalizacje",
    },
    time: {
      hour: "Godzina",
      minute: "Minuta",
      seconds: "Sekundy",
      day: "Dzień",
      month: "Miesiąc",
      week: "Tydzień",
      year: "Rok",
    },
    notifications: {
      title: "Notyfikacje",
      events: "Zdarzenia",
      empty: "Brak nowych notyfikacji",
      showAll: "Zobacz wszystkie",
    },
    themeMenu: {
      light: "Jasny",
      dark: "Ciemny",
      system: "System"
    },
    devParameters: {
      pave1: "Moc L1",
      pave2: "Moc L2",
      pave3: "Moc L3",
      pmax1: "Moc L1 - max",
      pmax2: "Moc L2 - max",
      pmax3: "Moc L3 - max",
      pmin1: "Moc L1 - min",
      pmin2: "Moc L2 - min",
      pmin3: "Moc L3 - min",
      vave1: "Napięcie L1",
      vave2: "Napięcie L2",
      vave3: "Napięcie L3",
      vmax1: "Napięcie L1 - max",
      vmax2: "Napięcie L2 - max",
      vmax3: "Napięcie L3 - max",
      vmin1: "Napięcie L1 - min",
      vmin2: "Napięcie L2 - min",
      vmin3: "Napięcie L3 - min",
      iave1: "Natężenie L1",
      iave2: "Natężenie L2",
      iave3: "Natężenie L3",
      imax1: "Natężenie L1 - max",
      imax2: "Natężenie L2 - max",
      imax3: "Natężenie L3 - max",
      imin1: "Natężenie L1 - min",
      imin2: "Natężenie L2 - min",
      imin3: "Natężenie L3 - min",
      eai1: "Energia pobrana L1",
      eai2: "Energia pobrana L2",
      eai3: "Energia pobrana L3",
      eae1: "Energia oddana L1",
      eae2: "Energia oddana L2",
      eae3: "Energia oddana L3",
      eri1: "Energia bierna pobrana L1",
      eri2: "Energia bierna pobrana L2",
      eri3: "Energia bierna pobrana L3",
      ere1: "Energia bierna oddana L1",
      ere2: "Energia bierna oddana L2",
      ere3: "Energia bierna oddana L3",
      pfave1: "Współczynnik mocy L1",
      pfave2: "Współczynnik mocy L2",
      pfave3: "Współczynnik mocy L3",
      pfmax1: "Współczynnik mocy L1 - max",
      pfmax2: "Współczynnik mocy L2 - max",
      pfmax3: "Współczynnik mocy L3 - max",
      pfmin1: "Współczynnik mocy L1 - min",
      pfmin2: "Współczynnik mocy L2 - min",
      pfmin3: "Współczynnik mocy L3 - min",
      energy3phases: "Całkowita energia",
      renergy3phases: "Całkowita energia bierna",
      power3phases: "Suma mocy",
      energy3phases_export: "Całkowita energia oddana",
      energy3phases_import: "Całkowita energia pobrana",
      renergy3phases_export: "Całkowita energia bierna, oddana",
      renergy3phases_import: "Całkowita energia bierna, pobrana",
      frq1: "Częstotliwość",
      vdc1: "Napięcie DC1",
      vdc2: "Napięcie DC2",
      vdc3: "Napięcie DC3",
      idc1: "Natężenie DC1",
      idc2: "Natężenie DC2",
      alarm: "Alarm",
      balance: "Bilans",
    },
    userProfile: {
      account: "Konto",
      title: "Profil użytkownika",
      edit: "Edytuj",
      myProfile: "Mój profil",
      language: "Język",
      email: "Email",
      emailTooltip: "Adres email nie może być edytowany",
      fullname: "Imię nazwisko",
      country: "Kraj",
      timezone: "Strefa czasowa",
      countryTooltip: "Kraj instalacji urządzenia.",
      countryRequired: "Pole Kraj jest wymagane",
      timezoneTooltip: "Strefa czasowa w której zainstalowane jest urządzenie.",
      timezoneRequired: "Strefa czasowa jest wymagana.",
      infoTitleOk: "Wszystko w porządku!",
      infoDescriptionOk: "Nie znaleźliśmy problemów związanych z Twoim kontem.",
      infoTitleWrong: "Potrzebujemy Twojej uwagi",
    },
    languages: {
      english: "Angielski",
      polish: "Polski",
      turkish: "Turecki",
    },
    forms: {
      successfullySaved: "Poprawnie zapisano dane.",
      successfullyExported: "Poprawnie wyeksportowano dane",
      errorInSaving: "Nie udało się zapisać danych. Sprawdź formularz.",
      errorInExporting: "Nie udało się wyeksportować danych.",
      submit: "Zatwierdź",
      discard: "Wyczyść",
      ok: "Ok",
      okGotIt: "Ok, zrozumiałem!",
      edit: "Edytuj",
      cancel: "Anuluj",
      export: "Eksportuj",
    },
    deviceOverview: {
      title: "Szczegóły urządzenia",
      edit: "Edytuj",
      name: "Nazwa",
      friendlyName: "Nazwa z urządzenia",
      typeName: "Nazwa typu",
      mac: "Mac",
      modelName: "Nazwa modelu",
      registeredDate: "Data rejestracji",
      modifiedDate: "Data modyfikacji",
      registeredDateTooltip: "Data kiedy urządzenie zostało zarejestowane w systemie",
      modifiedDateTooltip: "Data kiedy urządzenie zmieniło swoje dane",
      nameTooltip: "Nazwa ustalana przez użytkownika, możesz nią zmienić.",
      billingStartDate: "Data rozpoczęcia okresu rozliczeniowego",
      billingNoMonths: "Długość okresu rozliczeniowego w miesiącach",
      defaultTariff: "Taryfa rozliczeniowa urządzenia",
      location: "Lokalizacja",
      notificationsFromDevice: "Powiadomienia PUSH",
      notificationsEnabled: "Włączone",
      notificationsDisabled: "Wyłączone",
      notifyWhenVoltageDrops: "Powiadamiaj o spadku napięciu przy",
      notifyWhenVoltageRises: "Powiadamiaj o przekroczeniu napięcia po",
      notifyWhenTotalCosumptionAbove: "Powiadamiaj o przekroczeniu zużycia energii powyżej",
      disableNotificationsAtNight: "Nie wysyłaj powiadomień w nocy (22:00 - 7:00)",
      notificationInterval: "Wysyłaj powiadomienia nie częściej niż",
      muteNotifications: "Wycisz powiadomienia",
      muteNotificationsInfo: "Powiadomienia nie będą wysyłane z systemu telemetrii. Obejmuje wszystkie typy powiadomień."
    },
    tariff: {
      pageTitle: "Statystyki zużycia energii",
      chceckUsages: "Sprawdź zużycia",
      g11Description: "Podstawowa taryfa roliczenieowa. Jedna stawka za energię elektryczną o każdej porze dnia i nocy.",
      g12Description: "Taryfa dwustrefowa. Dla korzystających z energii elektrycznej głównie w nocy, w szczególności na potrzeby ogrzewania.",
      g12asDescription: "Taryfa dwustrefowa. Dostosowana do celów grzewczych.",
      g12rDescription: "Taryfa dwustrefowa. Podobna do G12, nastawiona jeszcze bardziej na zużycie energii w nocy.",
      g12nDescription: "Taryfa dwustrefowa. Rozliczenie w taryfie dziennej i nocnej z rozszerzoną strefą nocną o niedzielę.",
      g12wDescription: "Taryfa dwustrefowa. Niższa cena prądu w weekend, w nocy i wybranych godzinach dnia.",
      profitCostText: "Orientacyjny zysk/koszt energii ",
      currentConsumption: "Bieżące zużycie",
      showCosts: "Pokaż koszt",
      area1: "Strefa dzienna",
      area2: "Strefa nocna",
      currentUsageDescription: "Zużycie energii z bieżącego miesiąca według taryfy domyślnej",
      attentionInfo: "Prezentowane dane mają charakter symulacyjny i nie mogą być podstawą do potencjalnych roszczeń w stosunku do jakiegokolwiek podmiotu.",
      yourTariff: "Twoja taryfa",
      tariffsTitile: "Taryfy",
      priceArea1: "Cena w strefie pierwszej (dziennej)",
      priceArea2: "Cena w strefie drugiej (nocnej)",
      priceArea3: "Cena w strefie trzeciej",
      priceConst: "Opłata stała",
      priceConstInfo: "Zsumuj wszystkie stałe wartości z swojej faktury za energię i ustaw tą wartość jako stałą opłatę.",
      g11SummaryText: "Podstawowa taryfa dla gospodarstw domowych",
      gxSummaryText: "Zużywasz energię w dwóch strefach: dziennej i nocnej",
      area1_2: "Strefa dzienna/nocna",
      g11DescTitile: "Komfortowa G11",
      g12DescTitile: "Elastyczna G12",
      g12asDescTitile: "Taryfa G12as",
      g12nDescTitile: "Taryfa G12n",
      g12rDescTitile: "Taryfa G12r",
      g12wDescTitile: "Taryfa G12w",
      monday_sunday: "Poniedziałek - niedziela",
      monday_saturday: "Poniedziałek - sobota",
      monday_friday: "Poniedziałek - piątek",
      saturdaySunday: "Sobota, niedziela i święta",
      sundayFeasts: "Niedziela i święta",
      zone: "Strefa",
      wholeDay: "Całodobowe",
      tariffRule: "Zasada taryfy:",
      g11DescRule: "Jedna stawka za energię elektryczną o każdej porze dnia i nocy. Oferta da korzystających z energii elektrycznej głównie w dzień, nie korzystających z energi elektrycznej do celów grzewczych.",
      g12DescRule: "Umożliwia rozlicznie zużytej energii w dwóch strefach czasowych. Oferta dla korzystających z energii elektrycznej głównie w nocy, w szczególności na potrzeby ogrzewania.",
      g12asDescRule: "Taryfa zakłada niższe stawki na energię elektryczną między godziną 22.00 a 6.00, pod warunkiem wykorzystania energii dla celów ogrzewania pomieszczeń lub zasilania samochodu elektrycznego.",
      g12nDescRule: "Grupa dwustrefowa: strefy, dzienna i nocna z rozszerzoną stefą nocną o niedzielę.",
      g12rDescRule: "Grupa dwustrefowa: strefa dzienna i nocna.",
      g12wDescRule: "Oferta dla klientów korzystających z energii elektrycznej w strefie dziennej i nocnej, z rozszerzoną strefą nocną o soboty i niedzielę.",
      cheaper: "tańsza",
      moreExpensive: "droższa",
      nightPeriod: "Okres nocny",
      dailyPeriod: "Okres dzienny",
      southernPeriod: "Okres południowy",
      morningPeriod: "Okres poranny",
      eveningPeriod: "Okres wieczorny",
      selectTariff: "Wybierz taryfę",
      noTariffSelected: "Nie wybrano taryfy",
      compareTariff: "Porównaj taryfy",
      compareTariffDescription: "Możesz porównać swoje zużycia energii elektrycznej względem różnych taryf energetycznych."
    },
    location: {
      indexTitle: "Lokalizacje",
      name: "Nazwa",
      code: "Kod",
      address: "Adres",
      city: "Miasto",
      timezone: "Strefa czasowa",
      energyTariff: "Taryfa energetyczna",
      edit: "Edytuj lokalizację",
      yourLocations: "Twoje lokalizacje",
      newLocationTitle: "Dodaj nową lokalizację",
      availableEnergyTariffs: "Dostępne taryfy energetyczne",
      resetDefaut: "Resetuj",
      restoredDefault: "Przywrócono wartości domyślne",
      successfullyDeleted: "Pomyślnie usunięto lokalizację"
    },
    toasts: {
      success: "Sukces",
      warning: "Ostrzeżenie",
      info: "Informacja",
      help: "Pomoc",
    },
    validators: {
      number: "Wprowadź poprawną liczbę",
      min: "Wartość musi być większa bądź równa ",
      max: "Wartość musi być mniejsza bądź równa ",
      dateRange: "Zakres dat jest wymagany",
      parameterMin: "Wybierz conajmniej jeden parametr",
      exportTo: "Wybierz format pliku do eksportu",
      required: "Pole nie może pozostać bez wartości"
    },
    exportData: {
      title: "Eksportuj dane",
      parameters: "Parametry",
      parametersHint: "Wybierz parametry do eksportu",
      dateRange: "Wybierz zakres dat",
      customFileName: "Własna nazwa pliku",
      customFileNameHint: "Wpisz własną nazwę pliku lub pozostaw puste pole",
      exportAllParams: "Eksportuj wszystkie dostępne parametry urządzenia",
      exportTo: "Eksportuj do pliku",
      exportUnavailableTitle: "Eksport niedostępny",
      exportUnavailableDesc: "Wykryliśmy wzmożoną ilość zapytań do serwera. Eksportowanie danych jest chwilowo niedostępne. Spróbuj ponownie później.",
      exportInBackround: "Zlecono eksport danych. Poinformujemy Cię kiedy plik będzie gotowy.",
    },
    times: {
      min15: "15 min.",
      min30: "30 min.",
      min60: "1 godzina",
      min1440: "24 godziny"
    },
    analytics: {
      selectOperation: "Wybierz akcję",
      dailyHeatmap: "Dzienne zużycie energii (heatmap)",
      monthlyHeatmap: "Miesięczne zużycie energii (heatmap)",
      weeklyHeatmap: "Tygodniowe zużycie energii (heatmap)",
      yearlyHeatmap: "Roczne zużycie energii (heatmap)",
      selectDevices: "Wybierz urządzenia",
      selectDateRange: "Wybierz okres czasu",
      sumCmpDesc: "Sumy zużycia energii",
      compareTitle: "Analiza porównawcza",
      compareDescription: "Porównaj parametry urządzeń w zakresie wybranych akcji lub zdefiniuj własną",
      noData: "Brak danych",
      analyze: "Analizuj",
    },
    providedBy: "Dostarczone przez",
    manufacturer: "F&F Filipowski Sp. k.",
    pleaseWait: "Proszę czekać...",
    dashboard: "Dashboard",
    authentication: "Autentykacja",
    signIn: "Zaloguj",
    signUp: "Zarejestruj",
    signOut: "Wyloguj",
    passwordReset: "Resetuj hasło",
    error404: "Wygląda na to, że nic tutaj nie ma",
    error500: "Wystąpił wewnętrzny błąd serwera. Skontaktuj się z administratorem",
    daily: "Dzienne",
    monthly: "Miesięczne",
    yearly: "Roczne",
    total: "Całkowite",
    select: "Wybierz",
    noData: "Brak danych",
    add: "Dodaj",
    deletePrompt: "Potwierdź operację usunięcia danych. Czy jesteś pewien?",
    yes: "Tak",
    no: "Nie",
    edit: "Edytuj",
    attention: "Uwaga",
    date: "Data",
    selectDate: "Wybierz datę",
    selectDateGlobalDesc: "Wybrana data będzie zastosowana do wszystkich wykresów",
    energyTableView: "Widok tabeli",
    lp: "Lp.",
    value: "Wartość",
    unit: "Jednostka",
  },
  // do translations below, change only strings without keys :)
  tr: {
    signInView: {
      signInTitle: "Telemetry Sistemine Giriş Yapın",
      email: "E-posta",
      password: "Şifre",
      continue: "devam et",
      successfull: "Başarılı bir şekilde giriş yaptınız.",
      failed: "Tekrar deneyin!",
      confirm: "Anlaşıldı!",
      rememberMe: "Beni hatırla",
      token: "Token",
      params: "Parametreler",
      showDemo: "Show demo"
    },
    deviceListWidget: {
      title: "Kayıtlı cihazlar",
      total: "Toplam",
      dName: "İsim",
      dModel: "Model",
      dStatus: "Durum",
      dActions: "Aktiviteler",
      location: "Location",
      tariff: "Tarife",
    },
    deviceDetailsView: {
      currentVoltage: "Voltaj",
      current: "Akım",
      currentPower: "Güç",
      voltageTitle: "Voltaj",
      powerTitle: "Güç",
      energyTitle: "Aktif Enerji",
      energyReactiveTitle: "Reaktif enerji",
      energyTitleExport: "Verilen Aktif Enerji",
      energyTitleImport: "Harcanan Aktif Enerji",
      energyReactiveExportTitle: "Verilen Reaktif Enerji",
      energyReactiveImportTitle: "Harcanan Reaktif Enerji",
      voltageDescription:
        "Zaman aralığında yakalanan minimum ve maksimum Voltaj değerlerini gösterir.",
      currentDescription:
        "Zaman aralığında yakalanan minimum ve maksimum Akım değerlerini gösterir.",
      powerDescription:
        "Zaman aralığında yakalanan minimum ve maksimum Güç değerlerini gösterir.",
      energyDescription:
        "Zaman aralığında yakalanan minimum ve maksimum Tüketilen Enerji değerlerini gösterir.",
      energy3Description:
        "Wartość energii czynnej pobranej i oddanej (zsumowna z 3 faz), zarejestrowana w przedziale czasu.",
      energyReactiveDescription:
        "Zaman aralığında yakalanan minimum ve maksimum Reaktif Enerji değerlerini gösterir.",
      phase1: "Faz 1",
      phase2: "Faz 2",
      phase3: "Faz 3",
      summary: "Özet",
      phase1dsc: "Faz 1 verisi",
      phase2dsc: "Faz 2 verisi",
      phase3dsc: "Faz 3 verisi",
      summarydsc: "3 faz verileri",
      power3phasesDescription: "Zaman aralığında yakalanan Toplam güç değerlerini gösterir.",
      voltage3phasesDescription: "Zaman aralığında yakalanan Faz Voltaj değerini gösterir.",
      current3phasesDescription: "Zaman aralığında yakalanan Faz Akım değerini gösterir.",
      energy3phasesDescription:
        "Zaman aralığında Toplam Aktif enerji ve her fazın Aktif Enerji değerlerini gösterir.",
      energyReactive3phasesDescription:
        "Zaman aralığında Toplam Reaktif enerji değerini gösterir.",
        energy3phasesImportDescription:
        "Zaman aralığında Alına Toplam Aktif enerji değerini gösterir.",
      energy3phasesExportDescription:
        "Zaman aralığında Verilen Toplam Aktif enerji değerini gösterir.",
      energyReactive3phasesImportDescription:
        "Zaman aralığında Alına Toplam Reaktif enerji değerini gösterir.",
      energyReactive3phasesExportDescription:
        "Zaman aralığındaki Verilen Toplam Reaktif enerji değerini gösterir.",
      energyConsumption: "Enerji tüketimi",
      fromBillingPeriod: "Faturalama periyodu",
      selectBillingPeriod: "Faturalama periyodu seçin",
      active: "Aktif",
      reactive: "Reaktif",
      volt: "V",
      amper: "A",
      watt: "W",
      outPower: "verilen",
      details: "Daha fazla",
      energyProductionTitle: "Energy production",
      carbonEmissionHint: "Your carbon emission is less. Thank you!",
      energyProductionDescription: "Shows given away active energy captured in time range.",
      solarPowerDescription: "Shows power value captured in time range.",
      energyBalance: "Balance",
      energyBalanceDescription: "Balance of active energy imported and exported, recorded in a time interval.",
      selectView: "Select view"
    },
    chartsDropdowns: {
      chartOptions: "Grafik seçeneği",
      date: "Tarih",
      dateRange: "Tarih aralığı",
      simpleChartDateHint: "Seçilen tarihten sonraki ilk 24 saat verileri görüntülenecek.",
      reset: "Sıfırla",
      apply: "Uygula",
    },
    pagesTitle: {
      dashboard: "Gösterge Paneli",
      devicedetails: "Cihaz hakkında",
      deviceData: "Cihaz verileri",
      locationIndex: "Lokasyonlar",
    },
    time: {
      hour: "Saat",
      minute: "Dakika",
      seconds: "Saniye",
      day: "Gün",
      month: "Ay",
      week: "Hafta",
      year: "Yıl",
    },
    notifications: {
      title: "Bildirimler",
      events: "Olaylar",
      empty: "Yeni bir bildirim yok.",
      showAll: "Hepsini gör",
    },
    themeMenu: {
      light: "Açık",
      dark: "Karanlık",
      system: "Sistem"
    },
    devParameters: {
      pave1: "Güç L1",
      pave2: "Güç L2",
      pave3: "Güç L3",
      pmax1: "Güç L1 - max",
      pmax2: "Güç L2 - max",
      pmax3: "Güç L3 - max",
      pmin1: "Güç L1 - min",
      pmin2: "Güçr L2 - min",
      pmin3: "Güç L3 - min",
      vave1: "Voltaj L1",
      vave2: "Voltaj L2",
      vave3: "Voltaj L3",
      vmax1: "Voltaj L1 - max",
      vmax2: "Voltaj L2 - max",
      vmax3: "Voltaj L3 - max",
      vmin1: "Voltaj L1 - min",
      vmin2: "Voltaj L2 - min",
      vmin3: "Voltaj L3 - min",
      iave1: "Akım L1",
      iave2: "Akım L2",
      iave3: "Akım L3",
      imax1: "Akım L1 - max",
      imax2: "Akım L2 - max",
      imax3: "Akım L3 - max",
      imin1: "Akım L1 - min",
      imin2: "Akım L2 - min",
      imin3: "Akım L3 - min",
      eai1: "Aktif enerji import L1",
      eai2: "Aktif enerji import L2",
      eai3: "Aktif enerji import L3",
      eae1: "Aktif enerji export L1",
      eae2: "Aktif enerji export L2",
      eae3: "Aktif enerji export L3",
      eri1: "Reactive enerji import L1",
      eri2: "Reactive enerji import L2",
      eri3: "Reactive enerji import L3",
      ere1: "Reactive enerji export L1",
      ere2: "Reactive enerji export L2",
      ere3: "Reactive enerji export L3",
      pfave1: "Power faktör L1",
      pfave2: "Power faktör L2",
      pfave3: "Power faktör L3",
      pfmax1: "Power faktör L1 - max",
      pfmax2: "Power faktör L2 - max",
      pfmax3: "Power faktör L3 - max",
      pfmin1: "Power faktör L1 - min",
      pfmin2: "Power faktör L2 - min",
      pfmin3: "Power faktör L3 - min",
      energy3phases: "Toplam aktif enerji Export",
      renergy3phases: "Toplam reaktif enerji Export",
      power3phases: "Toplam güç",
      energy3phases_export: "Toplam aktif enerji Export",
      energy3phases_import: "Toplam aktif enerji Import",
      renergy3phases_export: "Toplam reaktif enerji Export",
      renergy3phases_import: "Toplam reaktif enerji Import",
      balance: "Bilans",
    },
    userProfile: {
      account: "Hesap",
      title: "Profil bilgileri",
      edit: "Profili düzenle",
      myProfile: "Profilim",
      language: "Dil",
      email: "E-posta adresi",
      emailTooltip: "Email adresi düzenlenemez!",
      fullname: "Tam isim",
      country: "Ülke",
      timezone: "Zaman dilimi",
      countryTooltip: "Kurulumun yapıldığı ülke, saat dilimini ayarlanması için belirtilmeli.",
      countryRequired: "Ülke seçilmesi gerekli",
      timezoneTooltip: "Cihaz zaman dilimi",
      timezoneRequired: "Zaman dilimi gerekli!",
      infoTitleOk: "Herşey yolunda!",
      infoDescriptionOk: "Hesabınızla ilgili herhangi bir sorun bulamadık.",
      infoTitleWrong: "Herşey yolunda değil!",
    },
    languages: {
      english: "İngilizce",
      polish: "Lehçe",
      turkish: "Türkçe",
    },
    forms: {
      successfullySaved: "Veriler başarıyla kaydedildi.",
      successfullyExported: "Dışa veri çıkarılması başarılı.",
      errorInSaving: "Veri kaydedilemiyor. Formu kontrol edin.",
      errorInExporting: "Dışa veri çıkarılması sırasında hata oluştu!",
      submit: "Onayla",
      discard: "Sil",
      ok: "Tamam",
      okGotIt: "Tamam, anlaşıldı!",
      edit: "Düzenle",
      cancel: "İptal",
      export: "Çıkar",
    },
    deviceOverview: {
      title: "Cihaz bilgileri",
      edit: "Cihazı düzenle",
      name: "İsim",
      friendlyName: "Yaygın isim",
      typeName: "Tip adı",
      mac: "Mac",
      modelName: "Model adı",
      registeredDate: "Kayıt Tarihi",
      modifiedDate: "Değiştirilme tarihi",
      registeredDateTooltip: "Cihazın kaydedildiği tarih",
      modifiedDateTooltip: "Cihazın kendi zamanına müdahale tarihi",
      nameTooltip: "Kullanıcı tanımlı adı",
      billingStartDate: "Faturalama periyodu başlangıç tarihi",
      billingNoMonths: "Faturalama ay sayısı",
      defaultTariff: "Faturalama tarifesi",
      location: "Lokasyon",
      notificationsFromDevice: "Cihaz bildirimleri",
      notificationsEnabled: "Aktif",
      notificationsDisabled: "Pasif",
      notifyWhenVoltageDrops: "Voltaj değeri düşmesinde bildir",
      notifyWhenVoltageRises: "Voltaj değeri yükseldiğinde bildir",
      notifyWhenTotalCosumptionAbove: "Toplam enerji tüketim değeri yükseldiğinde bildir",
      disableNotificationsAtNight: "Disable notifications at night (22:00 - 7:00)",
      muteNotifications: "Mute notifications",
      muteNotificationsInfo: "Notifications will not be sent from the telemetry system. Includes all types of notifications."
    },
    //do not translate tariff section, it is not supported in countries different than Poland :)
    tariff: {
      pageTitle: "Stats of energy consumption",
      chceckUsages: "Check usages",
      g11Description: "Podstawowa taryfa roliczenieowa. Jedna stawka za energię elektryczną o każdej porze dnia i nocy.",
      g12Description: "Taryfa dwustrefowa. Dla korzystających z energii elektrycznej głównie w nocy, w szczególności na potrzeby ogrzewania.",
      g12asDescription: "Taryfa dwustrefowa. Dostosowana do celów grzewczych.",
      g12rDescription: "Taryfa dwustrefowa. Podobna do g12, nastawiona jeszcze bardziej na zużycie energii w nocy.",
      g12nDescription: "Taryfa dwustrefowa. Rozliczenie w taryfie dzienne i nocnej z rozszerzoną strefą nocną o niedzielę.",
      g12wDescription: "Taryfa dwustrefowa. Niższa cena prądu w weekend, w nocy i wybranych godzinach dnia.",
      profitCostText: "Potentail cost/profit",
      currentConsumption: "Current consumption",
      showCosts: "Show costs",
      area1: "Day zone",
      area2: "Night zone",
      currentUsageDescription: "Energy consumption for the current month at the default tariff",
      attentionInfo: "The presented data are simulated and cannot be basis for potential claims against any subject.",
      yourTariff: "Your tariff",
      tariffsTitile: "Tariffs",
      priceArea1: "Price in area one (daily)",
      priceArea2: "Price in area two (nightly)",
      priceArea3: "Price in area third",
      priceConst: "Const price",
      priceConstInfo: "Caluclate all fixed prices from your invoice and set it here",
      g11SummaryText: "Basic household tariff",
      gxSummaryText: "You consume energy in two zones: day and night",
      area1_2: "Day/night zone",
      g11DescTitile: "Komfortowa G11",
      g12DescTitile: "Elastyczna G12",
      g12asDescTitile: "Taryfa G12as",
      g12nDescTitile: "Komfortowa G11",
      g12rDescTitile: "Komfortowa G11",
      g12wDescTitile: "Komfortowa G11",
      monday_sunday: "Monday - sunday",
      monday_saturday: "Monday - saturday",
      monday_friday: "Monday - friday",
      saturdaySunday: "Saturday, sunday and holidays",
      sundayFeasts: "Sunday and holidays",
      zone: "Zone",
      wholeDay: "Round the clock",
      tariffRule: "Tariff rule:",
      g11DescRule: "One rate for electricity at any time of the day or night. The offer will be given to those using electricity mainly during the day, not using electricity for heating purposes.",
      g12DescRule: "It enables the settlement of consumed energy in two time zones. An offer for those who use electricity mainly at night, in particular for heating purposes.",
      g12asDescRule: "Two-zone group: day and night zone.",
      g12nDescRule: "Two-zone group: day and night zones with extended night zone by Sunday.",
      g12rDescRule: "Extended two-zone group: day and night zones.",
      g12wDescRule: "An offer for customers using day and night heating, with the night zone extended to include Saturdays and Sundays",
      cheaper: "Cheaper",
      moreExpensive: "more expensive",
      nightPeriod: "Night period",
      dailyPeriod: "Daily period",
      southernPeriod: "Southern period",
      morningPeriod: "Morning period",
      eveningPeriod: "Evening period",
    },
    location: {
      indexTitle: "Lokasyonlar",
      name: "İsim",
      code: "Kod",
      address: "Adres",
      city: "Şehir",
      timezone: "Zaman dilimi",
      energyTariff: "Enerji tarifesi",
      edit: "Düzenle",
      yourLocations: "Lokasyonunuz",
      newLocationTitle: "Yeni ekle",
      availableEnergyTariffs: "Uygun tarifiler",
      resetDefaut: "Varsayılana ayarla",
      restoredDefault: "Varsayılan değerler geri yüklendi!",
      successfullyDeleted: "Lokasyon başarılı şekilde silindi!",
    },
    toasts: {
      success: "Başarılı",
      warning: "Uyarı",
      info: "Bilgi",
      help: "Yardım",
    },
    validators: {
      number: "Must be a valid number, fix your input",
      min: "Must be greater than or equal to ",
      max: "Must be less than or equal to ",
      dateRange: "Date range is required",
      parameterMin: "Please select at least one parameter",
      exportTo: "Select file type to export",
      required: "Field cannot be empty"
    },
    exportData: {
      title: "Verileri dışa aktar",
      parameters: "Parametreler",
      parametersHint: "Dışa çıkarılacak parametreleri seçin",
      dateRange: "Zaman aralığı seçin",
      customFileName: "Özel dosya adı",
      customFileNameHint: "Özel dosya adını tanımlayın veya boş bırakın",
      exportAllParams: "Mevcut tüm parametreleri dışa aktarın",
      exportTo: "Aktarılacak dosya tipi",
      exportUnavailableTitle: "Dışa aktarma kullanılamıyor.",
      exportUnavailableDesc: "Sunucu meşgul olduğundan dışa aktarma yapılamıyor. Daha sonra tekrar deneyin.",
      exportInBackround: "Verilerin dışa aktarılması planlandı. Dosya hazır olduğunda sizi bilgilendireceğiz.",
    },
    times: {
      min15: "15 min.",
      min30: "30 min.",
      min60: "1 hour",
      min1440: "24 hours"
    },
    providedBy: "Sağlayan:",
    manufacturer: "F&F Filipowski Sp. k.",
    pleaseWait: "Lütfen, bekleyin...",
    dashboard: "Gösterge Paneli",
    authentication: "Authentication",
    signIn: "Giriş",
    signUp: "Üye ol",
    signOut: "Çıkış",
    passwordReset: "Şifre sıfırla",
    error404: "Burada sorun yok gibi görünüyor.",
    error500: "İç Sunucu Hatası. Lütfen,sunucu yöneticisi ile iletişime geçin.",
    daily: "Günlük",
    monthly: "Aylık",
    yearly: "Yıllık",
    total: "Toplam",
    select: "Seç",
    noData: "Veri yok",
    add: "Ekle",
    rompt: "Silmek istediğinizden emin misin?",
    yes: "Evet",
    no: "Hayır",
    edit: "Düzenle",
    date: "Date",
  },
};

const i18n = createI18n({
  legacy: false,
  locale: "en",
  globalInjection: true,
  messages,
});

export default i18n;
